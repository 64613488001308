<template>
  <div id="app" class="min-h-screen w-screen relative">
    <Navbar></Navbar>
    <router-view />
    <Footer></Footer>
  </div>
</template>
<script>
  import Navbar from '@/components/Nav.vue';
  import Footer from '@/components/Footer.vue';
  import Aos from 'aos';
  import 'aos/dist/aos.css'
  export default {
    components: { Navbar, Footer },
    data()
    {
        return {
        }
    },
    watch: {
      // Set meta tags
      $route(to) {
        this.setMeta(to.meta);
      },
    },
    methods: {
      setMeta(meta) {
        document.title = meta.title;
        if(meta.title === undefined)
        {
          document.title = 'Systemy informatyczne, aplikacje dla firm - Complaia Systems';
        }
      }
    },
    async created() {
      Aos.init();
    },
    mounted() {

    }
  }
</script>
<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
}
.fade-enter-from,.fade-leave-to {
  opacity: 0;
}
.fade-loader-enter-active, .fade-loader-leave-active {
    transition: opacity 0.5s ease;
}
.fade-loader-enter-from,.fade-loader-leave-to {
  opacity: 0;
}
body
{
  overflow-x: hidden;
}

</style>

