<template>
    <nav class="flex items-center justify-between p-6 lg:px-8 sticky top-0 bg-white z-50 shadow-sm w-screen" aria-label="Global">
        <div class="flex lg:flex-1 select-none">
            <a href="/" class="-m-1.5 p-1.5 hover:opacity-60">
                <span class="sr-only">Systemy informatyczne, aplikacje dla firm - Complaia Systems</span>
                <img class="h-8 w-auto" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/complaia-systems.appspot.com/o/samo%20Logo.svg?alt=media&token=d9eddf73-57d7-4124-9b6a-6917f9e21c18" alt="tworzymy oprogramowanie i systemy informatyczne dla firm" />
            </a>
        </div>
        <div class="hidden lg:flex lg:gap-x-12 select-none">
            <a href="/" class="text-sm font-semibold leading-6 hover:opacity-60">Główna</a>
            <a href="/uslugi" class="text-sm font-semibold leading-6 hover:opacity-60">Usługi</a>
            <a href="/technologia" class="text-sm font-semibold leading-6 hover:opacity-60">Technologia</a>
            <!-- <a href="/blog" class="text-sm font-semibold leading-6 hover:opacity-60">Blog</a> -->
            <a href="/firma" class="text-sm font-semibold leading-6 hover:opacity-60">O nas</a>
        </div>
        <div class="hidden lg:flex lg:flex-1 lg:justify-end select-none">
            <a href="/kontakt" class="text-sm font-semibold leading-6 text-purple-600 hover:opacity-60">Kontakt <span aria-hidden="true">&rarr;</span></a>
        </div>
        <div class="flex lg:hidden">
          <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click.prevent="mobileMenuOpen = true">
            <span class="sr-only">Otwórz menu mobilne</span>
            <Bars3Icon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
    </nav>
    <Dialog as="div" class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
        <div class="fixed inset-0 z-50 bg-gray-500 bg-opacity-50" />
        <DialogPanel class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div class="flex items-center justify-between">
            <a href="/" class="-m-1.5 p-1.5">
              <span class="sr-only">Systemy informatyczne i tworzenie aplikacji webowych</span>
              <img class="h-8 w-auto" loading="lazy" src="https://firebasestorage.googleapis.com/v0/b/complaia-systems.appspot.com/o/samo%20Logo.svg?alt=media&token=d9eddf73-57d7-4124-9b6a-6917f9e21c18" alt="Tworzenie aplikacji mobilnych, rozwiązania IT na miarę, integracje systemów informatycznych oraz usługi programistyczne." />
            </a>
            <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
              <span class="sr-only">Close menu</span>
              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div class="mt-6 flow-root">
            <div class="-my-6 divide-y divide-gray-500/10">
              <div class="space-y-2 py-6">
                <a href="/" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Główna</a>
              </div>
              <div class="space-y-2 py-6">
                <a href="/uslugi" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Usługi</a>
              </div>
              <div class="space-y-2 py-6">
                <a href="/technologia" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Technologia</a>
              </div>
              <!-- <div class="space-y-2 py-6">
                <a href="/blog" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Blog</a>
              </div> -->
              <div class="space-y-2 py-6">
                <a href="/firma" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">O nas</a>
              </div>
              <div class="space-y-2 py-6">
                <a href="/kontakt" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-purple-600 hover:bg-gray-50">Kontakt / Bezpłatna wycena</a>
              </div>
            </div>
          </div>
        </DialogPanel>
    </Dialog>
</template>
<script>
    import { Dialog, DialogPanel } from '@headlessui/vue'
    import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
    export default {
        components: { Dialog, DialogPanel, Bars3Icon, XMarkIcon },
        data()
        {
            return {
                mobileMenuOpen: false
            }
        },
        methods: {

        }
    }
</script>